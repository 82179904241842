.postcode-input-wrapper {
    display: flex;
    gap: 10px;
}

#sample3_postcode {
    flex-grow: 1;
}

.search-button {
    padding: 0.5rem 1rem;
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    white-space: nowrap;
}


#wrap {
    display: none;
    border: 1px solid #ccc;
    width: 100%;
    height: 300px;
    margin: 5px 0;
    position: absolute;
    left: 0;
    top: 0;
}

#btnFoldWrap {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -1px;
    z-index: 1;
}

.postal-wrapper {
    position: relative
}