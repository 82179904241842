.postcode-input-wrapper {
  gap: 10px;
  display: flex;
}

#sample3_postcode {
  flex-grow: 1;
}

.search-button {
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
  background-color: #000;
  border: none;
  border-radius: 4px;
  padding: .5rem 1rem;
}

#wrap {
  border: 1px solid #ccc;
  width: 100%;
  height: 300px;
  margin: 5px 0;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

#btnFoldWrap {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: -1px;
  right: 0;
}

.postal-wrapper {
  position: relative;
}
/*# sourceMappingURL=sign-up.daum.css.map */
